import React from 'react';
import '../styles/Footer.css';
import logo from '../assets/dmsl_logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faRss } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faGooglePlus,
  faBehance,
} from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* About Section */}
        <div className="footer-section">
          <h3>About Us</h3>
          <p>
            Our organization has a single-minded focus, to guide students to the
            best possible institutions, enabling them to build their future.
            We ensure personalized support tailored to every student’s needs.
          </p>
          <div className="logo">
          <img src={logo} alt="DMSL Logo" />
        </div>
        </div>

        {/* Office Locations */}
        <div className="footer-section">
          <h3>Northampton: Head Office</h3>
          <p>
            40 Queens Road, Northampton, England, NN1 3LP
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone} /> +44 7767 948782
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} /> abhishekdgaonkar@gmail.com
          </p>
        </div>

        {/* Contact Section */}
        <div className="footer-section">
          <h3>Contact</h3>
          <p>Abhishek Dgaonkar</p>
          <p>
            <FontAwesomeIcon icon={faPhone} /> +44 7767 948782
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} /> abhishekdgaonkar@gmail.com
          </p>
          <h4>Follow Us ON</h4>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://google.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGooglePlus} />
            </a>
            <a href="https://behance.net" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faBehance} />
            </a>
            <a href="/rss" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faRss} />
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="footer-bottom">
        <p>
          © 2024 DMSL UK LTD. All Rights Reserved. 
        </p>
        <nav>
          <a href="/">Home</a> / <a href="/about">About Us</a> /{' '}
          <a href="/news-events">News & Events</a> / <a href="/services">Services</a> /{' '}
          <a href="/courses">Courses</a> / <a href="/contact">Contact</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
