import React from 'react';
import '../styles/ServiceDetail.css';

// Replace with real images or video thumbnails
import serviceImage1 from '../assets/compscience.webp';
import serviceImage2 from '../assets/maths.webp';
import consultationImage from '../assets/consultation.webp';

const ServiceDetail: React.FC = () => {
  const services = [
    {
      id: 1,
      title: 'Computer Science',
      description: 'Learn programming, algorithms, and software development.',
      image: serviceImage1,
      enrollLink: '/enroll/computer-science',
    },
    {
      id: 2,
      title: 'Math and Science',
      description: 'Strengthen your knowledge of mathematics and science.',
      image: serviceImage2,
      enrollLink: '/enroll/math-science',
    },
    {
      id: 3,
      title: 'Consultation Services',
      description: 'Guidance for pursuing higher education abroad.',
      image: consultationImage,
      enrollLink: '/enroll/consultation',
    },
  ];

  const videos = [
    {
      id: 1,
      title: 'Introduction to Computer Science',
      src: '/assets/video1.mp4',
    },
    {
      id: 2,
      title: 'Math and Science Tutorials',
      src: '/assets/video2.mp4',
    },
    {
      id: 3,
      title: 'Consultation Services Overview',
      src: '/assets/video3.mp4',
    },
  ];

  return (
    <section className="service-detail">
      {/* Services Section */}
      <h1 className="section-title">Our Services</h1>
      <div className="services-grid">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <img src={service.image} alt={service.title} className="service-image" />
            <h2 className="service-title">{service.title}</h2>
            <p className="service-description">{service.description}</p>
            <a href={service.enrollLink} className="enroll-button">
              Enroll Now
            </a>
          </div>
        ))}
      </div>

      {/* Videos Section */}
      <h2 className="section-title">Demo Videos</h2>
      <div className="videos-grid">
        {videos.map((video) => (
          <div key={video.id} className="video-card">
            <h3 className="video-title">{video.title}</h3>
            <video controls className="video-player">
              <source src={video.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceDetail;
