// src/pages/Home.tsx
import React from 'react';
import '../styles/Home.css';
import Slider from 'react-slick';
import '../styles/Carousel.css'; 
import heroImage from '../assets/Home.webp'; // Replace with your hero image path
import whyDMSLImage from '../assets/why_dmsl.webp'; // Replace with your Why DMSL image
import studyAbroadImage from '../assets/study_abroad.webp'; // Replace with your Study Abroad image
import servicesImage from '../assets/services.webp'; // Replace with your Our Services image

// Carousel Images
import image1 from '../assets/compscience.webp';
import image2 from '../assets/maths.webp';
import image3 from '../assets/consultation.webp';


  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1300,
    arrows: false,
  };

  const carouselItems = [
    {
      id: 1,
      title: 'Computer Science',
      subtitle: 'We offer courses in Computer Science, including programming, software engineering, and data analysis.Price Range: £3000 - £7500',
      image: image1,
    },
    {
      id: 2,
      title: 'Math and Science',
      subtitle: 'Our Math and Science courses are designed to enhance critical thinking and analytical skills.Price Range: £3500 - £7200',
      image: image2,
    },
    {
      id: 3,
      title: 'Consultation Services',
      subtitle: 'We provide consultation services for abroad education, guiding students in selecting the best opportunities.Price Range: £4000 - £7800',
      image: image3,
    },
  ];


const Home: React.FC = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-overlay">
          <h1 className="hero-title">DMSL Educational Services</h1>
          <p className="hero-subtitle">Empowering Future Leaders</p>
          <p className="hero-description">
            At DMSL, we offer tailored educational and consultation services to help students achieve their dreams.
            Whether you want to excel academically or explore opportunities abroad, we’ve got you covered.
          </p>
          <a href="/contact" className="hero-button">Contact Us</a>
          <p className="it-services-info">
  We are excited to announce that DMSL will soon begin offering cutting-edge IT services to cater to your technological needs. Stay tuned for updates!
</p>

        </div>
      </section>

     {/* Carousel Section */}
     <section className="carousel-section">
     <h2 className="carousel-title">Explore moments from our educational programs</h2>
        <Slider {...carouselSettings}>
          {carouselItems.map((item) => (
            <div key={item.id} className="carousel-item">
              <img src={item.image} alt={item.title} className="carousel-image" />
              <div className="carousel-content">
                <h3>{item.title}</h3>
                <p>{item.subtitle}</p>
              </div>
            </div>
          ))}
        </Slider>
      </section>


      {/* Highlights Section */}
<section className="highlights">
  <h2 className="section-title">Our Highlights</h2>
  <div className="highlights-grid">
    <div className="highlight">
      <img src={whyDMSLImage} alt="Why DMSL" className="highlight-image" />
      <div className="highlight-content">
        <h3>Why DMSL?</h3>
        <p>
          At DMSL, we guide students towards academic and career success with personalized plans tailored to their unique goals.
        </p>
        
  <a href="/more-info" className="highlight-button">More Info</a>


      </div>
    </div>
    <div className="highlight">
      <img src={studyAbroadImage} alt="Study Abroad" className="highlight-image" />
      <div className="highlight-content">
        <h3>Study Abroad</h3>
        <p>
          Explore opportunities in top universities around the globe, with expert guidance from DMSL’s team.
        </p>
        <button className="highlight-button">More Info</button>
      </div>
    </div>
    <div className="highlight">
      <img src={servicesImage} alt="Our Services" className="highlight-image" />
      <div className="highlight-content">
        <h3>Our Services</h3>
        <p>
          From tailored tutorials to overseas consultation, DMSL is committed to helping you achieve your dreams.
        </p>
        
        <a href="/ServiceDetail" className="highlight-button">
  More Info
</a>
        
      </div>
    </div>
  </div>
</section>


      {/* Quick Contact Section */}
      <section className="quick-contact">
        <h2 className="section-title">Quick Contact Now</h2>
        <form className="contact-form">
          <input type="text" placeholder="Name (required)" required />
          <input type="email" placeholder="Email (required)" required />
          <input type="text" placeholder="Phone (required)" required />
          <textarea placeholder="Message" required></textarea>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </section>
    </div>
  );
};

export default Home;
