// src/pages/StudyAbroad.tsx
import React from 'react';
import '../styles/StudyAbroad.css';
import usaImage from '../assets/gallery-image2.webp';
import ukImage from '../assets/gallery-image4.webp';
import australiaImage from '../assets/gallery-image5.jpg';
import newZealandImage from '../assets/gallery-image1.webp';
import irelandImage from '../assets/gallery-image3.webp';

const StudyAbroad: React.FC = () => {
  const destinations = [
    {
      name: 'USA',
      description: 'World-class education system and a hub for technology and research.',
      image: usaImage,
    },
    {
      name: 'UK',
      description: 'Renowned universities with a rich cultural heritage.',
      image: ukImage,
    },
    {
      name: 'Australia',
      description: 'Diverse educational programs and excellent student lifestyle.',
      image: australiaImage,
    },
    {
      name: 'New Zealand',
      description: 'High-quality education with a focus on innovation and research.',
      image: newZealandImage,
    },
    {
      name: 'Ireland',
      description: 'Friendly environment and top-notch education system.',
      image: irelandImage,
    },
  ];

  return (
    <section className="study-abroad">
      <h1 className="title">Study Abroad</h1>
      <p className="description">
        Explore world-class education opportunities in the top countries globally. Your journey to success begins here!
      </p>
      <div className="destinations-grid">
        {destinations.map((destination, index) => (
          <div key={index} className="destination-card">
            <img src={destination.image} alt={destination.name} className="destination-image" />
            <h2 className="destination-name">{destination.name}</h2>
            <p className="destination-description">{destination.description}</p>
            <button className="explore-button">Explore Programs</button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StudyAbroad;
