// src/pages/MoreInfo.tsx
import React from 'react';
import '../styles/MoreInfo.css';
import whyDMSLImage from '../assets/why_dmsl.webp'; // Replace with the correct image path

const MoreInfo: React.FC = () => {
  return (
    <div className="more-info">
      <div className="hero-section">
        <img src={whyDMSLImage} alt="Why DMSL?" className="hero-image" />
        <div className="hero-text">
          <h1>Why Choose DMSL?</h1>
          <p>
            At DMSL, we have been a trusted educational partner for students across the UK. 
            With a legacy of excellence, we provide personalized educational guidance and consultation 
            for overseas studies.
          </p>
          <h2>Our Advantages</h2>
        <ul>
          <li>16+ years of experience in educational services.</li>
          <li>Global educational reach with partnerships worldwide.</li>
          <li>Expert tutors with specialized knowledge in various domains.</li>
          <li>Comprehensive career planning and guidance.</li>
          <li>Proven success stories of students achieving their academic dreams.</li>
        </ul>
        </div>
      </div>

      

      <section className="call-to-action">
        <h2>Let’s Achieve Your Dreams Together</h2>
        <p>
          Join DMSL today and unlock your potential with our tailored educational services. 
          Whether it’s excelling in academics or exploring opportunities abroad, we’re here to help.
        </p>
        <a href="/contact" className="action-button">Get in Touch</a>
      </section>
    </div>
  );
};

export default MoreInfo;
