// src/pages/Services.tsx
import React from 'react';
import '../styles/Services.css';
import service1Image from '../assets/Service1.webp';
import service2Image from '../assets/service2.webp';

// Service data array for scalability
const services = [
  {
    id: 1,
    title: 'Personalized Tutoring',
    description: 'We offer customized tutoring solutions tailored to individual needs.',
    image: service1Image,
    link: '/service-detail?service=1',
  },
  {
    id: 2,
    title: 'Career Guidance',
    description: 'Professional career advice to help you make informed decisions.',
    image: service2Image,
    link: '/service-detail?service=2',
  },
];

const Services: React.FC = () => {
  return (
    <section className="services">
      <h2>Our Services</h2>
      <div className="services-grid">
        {services.map((service) => (
          <div key={service.id} className="service">
            <img src={service.image} alt={service.title} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <a href={service.link} className="enroll-button">
              Enroll Now
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
