// src/pages/Contact.tsx
import React from 'react';
import '../styles/Contact.css';

const Contact: React.FC = () => {
  return (
    <section className="contact">
      <div className="contact-container">
        {/* Left Section: Contact Form */}
        <div className="contact-form-section">
          <h2 className="form-title">Quick Contact Now</h2>
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name (required)</label>
              <input type="text" id="name" name="name" placeholder="Enter your name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email (required)</label>
              <input type="email" id="email" name="email" placeholder="Enter your email" required />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone (required)</label>
              <input type="tel" id="phone" name="phone" placeholder="Enter your phone number" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" placeholder="Write your message here"></textarea>
            </div>
            <button type="submit" className="submit-button">Send Message</button>
          </form>
        </div>

        {/* Right Section: Contact Details */}
        <div className="contact-details">
          <h2 className="details-title">Get In Touch</h2>
          <p>
            <strong>Address:</strong> 40 Queens Road, Northampton, England, NN1 3LP
          </p>
          <p>
            <strong>Email:</strong> <a href="mailto:abhishekdgaonkar@gmail.com">abhishekdgaonkar@gmail.com</a>
          </p>
          <p>
            <strong>Phone:</strong> <a href="tel:+44 7767 948782">+44 7767 948782</a>
          </p>
          <p>
            <strong>Office Hours:</strong> Monday - Friday, 9:00 AM - 5:00 PM
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.8903383011667!2d-0.9019730845123439!3d52.24125687976502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487710df7a45f5e1%3A0x1a5161c0df2513c1!2s40%20Queens%20Rd%2C%20Northampton%20NN1%203LP%2C%20UK!5e0!3m2!1sen!2sus!4v1679674206019!5m2!1sen!2sus"
            className="map"
            allowFullScreen
            loading="lazy"
            title="Google Map"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
