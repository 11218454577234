import React, { useState } from 'react';
import '../styles/Navbar.css';
import logo from '../assets/dmsl_logo.webp'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faHome,
  faUser,
  faBook,
  faGraduationCap,
  faBriefcase,
  faNewspaper,
  faComments,
  faBars,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faGooglePlus,
} from '@fortawesome/free-brands-svg-icons';

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      {/* Top Bar */}
      <div className="top-bar">
        <div className="contact-info">
          <span>
            <FontAwesomeIcon icon={faPhone} /> (+44) 7767 948782
          </span>
          <span>
            <FontAwesomeIcon icon={faEnvelope} /> abhishekdgaonkar@gmail.com
          </span>
        </div>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href="https://google.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGooglePlus} />
          </a>
        </div>
      </div>

      {/* Main Navbar */}
      <nav className="main-navbar">
        <div className="logo">
          <img src={logo} alt="DMSL Logo" />
        </div>
        <div className="company-name">
          <h1>DMSL Educational Services</h1>
          <p>Empowering Future Leaders</p>
        </div>

        {/* Hamburger Menu */}
        <div className="menu-icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </div>

        {/* Navbar Links */}
        <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <li>
            <a href="/">
              <FontAwesomeIcon icon={faHome} /> Home
            </a>
          </li>
          <li>
            <a href="/about">
              <FontAwesomeIcon icon={faUser} /> About Us
            </a>
          </li>
          <li>
            <a href="/study-abroad">
              <FontAwesomeIcon icon={faGraduationCap} /> Study Abroad
            </a>
          </li>
          <li>
            <a href="/student-zone">
              <FontAwesomeIcon icon={faBook} /> Student Zone
            </a>
          </li>
          <li>
            <a href="/services">
              <FontAwesomeIcon icon={faBriefcase} /> Services
            </a>
          </li>
          <li>
            <a href="/news-events">
              <FontAwesomeIcon icon={faNewspaper} /> News & Events
            </a>
          </li>
          <li>
            <a href="/contact">
              <FontAwesomeIcon icon={faComments} /> Contact Us
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
