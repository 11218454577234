// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/About';
import StudyAbroad from './pages/StudyAbroad';
import StudentZone from './pages/StudentZone';
import Services from './pages/Services';
import MoreInfo from './pages/MoreInfo';
import ServiceDetail from './pages/ServiceDetail';

import Contact from './pages/Contact';

const App: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/study-abroad" element={<StudyAbroad />} />
        <Route path="/student-zone" element={<StudentZone />} />
        <Route path="/services" element={<Services />} />
        <Route path="/more-info" element={<MoreInfo />} />
        <Route path="/ServiceDetail" element={<ServiceDetail />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
