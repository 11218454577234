// src/pages/StudentZone.tsx
import React from 'react';
import '../styles/StudentZone.css';
import faqImage from '../assets/About.webp';
import resourcesImage from '../assets/gallery-image1.webp';
import testimonialsImage from '../assets/gallery-image3.webp';

const StudentZone: React.FC = () => {
  const features = [
    {
      title: 'FAQs',
      description: 'Get answers to all your questions about studying abroad and our services.',
      image: faqImage,
    },
    {
      title: 'Student Resources',
      description: 'Access a variety of study materials, guides, and useful tools for academic success.',
      image: resourcesImage,
    },
    {
      title: 'Testimonials',
      description: 'Read inspiring stories from our students who achieved their dreams.',
      image: testimonialsImage,
    },
  ];

  return (
    <section className="student-zone">
      <h1 className="title">Student Zone</h1>
      <p className="description">
        Welcome to the Student Zone. Here you can find valuable resources and insights to help you achieve your goals.
      </p>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <img src={feature.image} alt={feature.title} className="feature-image" />
            <h2 className="feature-title">{feature.title}</h2>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StudentZone;

